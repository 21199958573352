import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { API_BASE_URL, MEDIA_URL } from "../../config";
import { CAlert, CFormCheck, CSpinner, CButton } from '@coreui/react'
import { Link } from 'react-router-dom';


const trainerRegister = (props) => {
    const { t } = useTranslation();
    const [gender, setGender] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [submittedButton, setSubmittedButton] = useState(false);
    const [lastName, setLasName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [domaines, setDomaines] = useState([]);
    const [domaine, setDomaine] = useState(null);
    const [specialtys, setSpecialtys] = useState([]);
    const [specialty, setSpecialty] = useState(null);
    const [city, setCity] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otherPhone, setOtherPhone] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/specialtys`)
            .then((result) => {
                console.log('speccccc list ', result);
                setDomaines(result.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    const handleDomaineChange = (e) => {
        setDomaine(e.target.value);
        let id = e.target.value;
        console.log("specidddd", e.target.value);

        if (id.toString() !== "0") {
            let specs = domaines.find((el) => el.id.toString() === id);
            console.log("specs---", specs);

            setSpecialtys(specs.specialtys);
            console.log("specs", specialtys);
        } else {
            setSpecialty(null);
            //  setModel("");
        }
    };

    const validEmailRegex = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (validEmailRegex.test(email) && lastName && firstName && domaine && specialty && country && city && phoneNumber) {
            setSubmittedButton(true);
            const formData = new FormData();
            formData.append("gender", gender);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("domaine", domaine);
            formData.append("specialty", specialty);
            formData.append("email", email);
            formData.append("country", country);
            formData.append("city", city);
            formData.append("phoneNumber", phoneNumber);
            formData.append("otherPhone", otherPhone);
            axios
                .post(`${API_BASE_URL}/apply/add`, formData)
                .then((response) => {
                    console.log(response); //return;
                    if (response.data.status == "ok") {
                        setError(false)
                        setSuccess(true);
                        setSubmitted(false);
                        setSubmittedButton(false);
                    } else {
                        setError(true)
                        setSuccess(false);
                        setSubmitted(false);
                        setSubmittedButton(false);
                    }
                })
                .catch((response) => {

                    console.log('errrrrrrrr------------');
                    console.log(response);
                    setError(true)
                    setSuccess(false);
                    setSubmitted(false);
                    setSubmittedButton(false);
                });



        } else {
            window.scrollTo({ top: 150, behavior: "smooth" });
        }
    }
    if (success)
        return (
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ textAlign: "center", "marginBottom": "10%", "padding": "15px", "borderRadius": "4px" }}>

                <div className="loading_logo">
                    <br />
                    <img
                        src={"/assets/icons/success.png"}
                        alt="success icon"
                        className='successIconGreen'
                    /><br /><br />
                    <p style={{ color: "#1c9070", textAlign: "center", fontSize: "22px" }}>
                        {t("apply_submitted")}
                    </p>
                </div>

            </div>
        );
    else return (
        <>
            <div className="w_commercial_alternating_cards cc_text_group_7 registerForm" style={{ "marginBottom": "10%", "padding": "15px", "borderRadius": "4px", "width": "70%", "margin": "auto" }}>
                <br /> <br />
                <h3> {t("join_as_trainer")}</h3>
                <h4>{t("Merci_renseigner")}</h4> <br />

                <br />
                <form onSubmit={handleSubmit}>


                    <div className="fsRowBody">

                        <CFormCheck
                            type="radio"
                            inline
                            name="gender"
                            id="Mr"
                            label="Mr"
                            value="Mr"
                            onChange={(e) => setGender(e.target.value)}
                        />
                        <CFormCheck
                            type="radio"
                            inline
                            name="gender"
                            id="Mme"
                            label="Mme"
                            value="Mme"
                            onChange={(e) => setGender(e.target.value)}
                        />
                        {submitted && !gender && (
                            <span className="invalid-feedback error">
                                {t("required")}
                            </span>
                        )}
                    </div>
                    <div className="w-layout-grid ">
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="lastName">{t("nom")}
                                <span className="fsRequiredMarker">*</span> </label>
                            <input type="text" className="form-control" name="lastName" value={lastName} onChange={(e) => setLasName(e.target.value)}></input>
                            {submitted && !lastName && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}
                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="firstName">{t("prenom")}
                                <span
                                    className="fsRequiredMarker">*</span> </label>
                            <input type="text" className="form-control" name="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                            {submitted && !firstName && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}

                        </div>
                    </div>
                    <div className="w-layout-grid ">
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="domaine">Domaine<span
                                className="fsRequiredMarker">*</span> </label>
                            <select
                                value={domaine}
                                name="formateurs"
                                className="form-control"
                                onChange={(e) => {
                                    handleDomaineChange(e);
                                }}
                            >
                                <option value="0"> Choisissez le domaine</option>
                                {domaines.map((el) => {
                                    return (
                                        <option key={el.id} value={el.id}>
                                            {el.label}
                                        </option>
                                    );
                                })}
                            </select>

                            {submitted && !domaine && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}
                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="specialty">{t("specialty")}<span
                                className="fsRequiredMarker">*</span> </label>
                            <select
                                value={specialty}
                                name="formateurs"
                                className="form-control"
                                onChange={(e) => {
                                    setSpecialty(e.target.value);
                                }}
                            >
                                <option value="0">-</option>
                                {specialtys.map((el) => {
                                    return (
                                        <option key={el.id} value={el.id}>
                                            {el.label}
                                        </option>
                                    );
                                })}
                            </select>

                            {submitted && !specialty && (
                                <span className="invalid-feedback error">
                                    {t("required")}
                                </span>
                            )}
                        </div>

                    </div>
                    <div className="w-layout-grid ">

                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="country">{t("pays")}<span
                                className="fsRequiredMarker">*</span> </label>
                            <CountryDropdown
                                value={country}
                                className="form-control"
                                defaultOptionLabel="Choisissez un pays"
                                onChange={(val) => setCountry(val)}
                                priorityOptions={["TN"]}
                                blacklist={['IL']}
                            />
                            {submitted && !country && (
                                <span className="invalid-feedback error">{t("required")}</span>
                            )}
                        </div>
                        <div>
                            <div className="fsRowBody">
                                <label className="fsLabel fsRequiredLabel" htmlFor="city">{t("ville")}<span
                                    className="fsRequiredMarker">*</span> </label>
                                <RegionDropdown
                                    country={country}
                                    value={city}
                                    className="form-control"
                                    onChange={(val) => setCity(val)}
                                />
                                {submitted && !city && (
                                    <span className="invalid-feedback error">{t("required")}</span>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="w-layout-grid ">

                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="email">E-mail<span
                                className="fsRequiredMarker">*</span> </label>
                            <input type="text" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                            {submitted && !validEmailRegex.test(email) && (
                                <span className="invalid-feedback error">
                                    Invalid email
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="w-layout-grid ">
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel"
                                htmlFor="phoneNumber">{t("mobile_phone")}<span
                                    className="fsRequiredMarker">*</span> </label>
                            <PhoneInput
                                type="text"
                                onChange={setPhoneNumber}
                                placeholder="Enter phone number"
                                defaultCountry="TN"
                                international
                                name="phoneNumber"
                                id="phoneNumber"
                                value={phoneNumber}

                            />
                            {submitted && !isValidPhoneNumber(phoneNumber) && (
                                <span className="invalid-feedback error">{t("required")}</span>
                            )}
                        </div>
                        <div className="fsRowBody">
                            <label className="fsLabel fsRequiredLabel" htmlFor="otherPhone">{t("autre_phone")}
                                <span
                                    className="sp_beige_text"> ({t("facultatif")})</span> </label>
                            <PhoneInput
                                type="text"
                                onChange={setOtherPhone}
                                placeholder="Enter phone number"
                                defaultCountry="TN"
                                international
                                name="otherPhone"
                                id="otherPhone"
                                value={otherPhone}

                            />
                        </div>
                    </div>
                    <br />

                    {error && (
                        <CAlert color="danger" >
                            {t("error_try_again")}
                        </CAlert>
                    )}

                    {submitted && success && (<CAlert color="success">
                        {/* {t("gdtc_create_check_mail")} */}
                        Your GDTC account has just been created Please check your email for confirmation code
                    </CAlert>)}
                    <div className="w-layout-grid ">
                        {/* <input disabled={submitted} id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                         type="submit" value={t("creer_compte_submit")} /> */}
                        <CButton className="btn_black w-button btn-block" type="submit"
                            disabled={submittedButton} > {submittedButton && <CSpinner color="default" size="sm" />} {t("apply_now")} </CButton>
                    </div>

                </form>
            </div>
        </>
    )
}

export default trainerRegister
