import { CCol } from "@coreui/react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { MEDIA_URL } from "src/config";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

export default function CourseCard(props) {
  const { t } = useTranslation();
  const show = { display: "inline-block" };
  const hide = { display: "none" };
  const [errorImg, setErrorImg] = useState(false);

  useEffect(() => {
  }, [props.id]);


  const handleErrorImg = () => {
    setErrorImg(true)
  };

  return (
    <div className="itemCard">
      <Link to={props.medical ? `/GMTC-medical-center/trainings/${props.id}`
        : `/trainings/${props.id}`
      } className="itemCardLink">
        <div className="c-posts__media vehicle-media ">
          {/* <img className=" similar-car" src={props.image} alt={props.title} /> */}
          {0 && props.path ? <video autoPlay="autoplay" controls muted style={{ borderRadius: '10px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', }}>
            <source src={MEDIA_URL + props.path} type="video/mp4" />
          </video>
            : <div className="imgContainer">
              {props.image ?
                <img src={MEDIA_URL + props.image} onError={handleErrorImg} />
                : <img className="defaultImg" src='/assets/logo-gdtc.png' onError={handleErrorImg} />
              }
            </div>}
        </div>
        <div className="row pt-4 pb-4 pl-4">
          <CCol md="12">
            <h4 className="itemCardTitle oneLineOnly2 oneLineOnly3">
              {props.title}
            </h4>
            {/* <p className="itemCardSubtitle">{props.subtitle}</p> */}
          </CCol>
          <div className="courseDateCard">
            {t("starts_at")}  <Moment format="DD-MM-YYYY">{props.start}</Moment>
          </div>
          <CCol md="12" className="cardBottomRow12">
            <div className="cardBottomRow">
              {/* <div className="sessionRate"><img src='assets/images/star-icon.svg' alt="gdtc star icon"/> {props.rate}</div> */}
              <div className="sessionsCount">{props.sessionsCount} {props.sessionsCount > 1 ? "sessions" : "session"}</div>
              <div className="sessionDuration"> 55 minutes </div>

            </div>
          </CCol>

        </div>
      </Link>
    </div>
  );
}
