import React, { useState, useEffect } from 'react';
import FavoriteContext from './FavoriteContext';
import { API_BASE_URL, consol } from 'src/config';
import axios from 'axios';
import apiClient from '../apiClient';
import { useLocation } from 'react-router-dom';

const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [ordersT, setOrdersT] = useState([]);
  const [ordersS, setOrdersS] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersSessions, setOrdersSessions] = useState([]);
  const [sColor, setSColor] = useState(null);
  const [pColor, setPColor] = useState(null);
  const [tColor, setTColor] = useState(null);
  const location = useLocation();

  const primaryColor = (pathname) => {
    return pathname.includes('medical-center') ? '#22427c' : '#26bbcd';
  };
  const secondColor = (pathname) => {
    return pathname.includes('medical-center') ? '#D4D3DC' : '#c4dcdb';
  };
  const thirdColor = (pathname) => {
    return pathname.includes('medical-center') ? '#3465c23b' : '#26bbcd26';
  };

  const updateFavorites = (id) => {
    //console.log("glob id-",id,'-------table------',table , tokenCookie);
    //if(tokenCookie){
    return new Promise((resolve, reject) => {

      const formData = new FormData();
      // formData.append("user", localStorage.getItem("id"));
      formData.append("item", id);
      apiClient.post("/member-api/setFavorite", formData)
        .then((res) => {
          console.log('set res', res);
          if (res.data.action === "delete") {
            //console.log("delete glob");
            setFavorites((prevFavorites) =>
              prevFavorites.filter((item) => item != id)
            );
          } else if (res.data.action === "add") {
            //console.log("add glob");
            setFavorites((prevFavorites) => [...prevFavorites, id]);
          }
          resolve(true);
          //console.log("fav list updated glob - " ,favorites);

        })
        .catch((error) => {
          console.log('erro fav', error);
          reject(false);
        });
    });
    //} else {return 'log';}
  };


  const checkFavorite = (id) => {
    if (favorites) {

      return favorites.includes(id);
    } else return false
  };

  const removeFromFavorites = (id) => {
    //console.log("glob  remove id-",id,'-------table------',table);
    if (favorites) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        //formData.append("user",  localStorage.getItem("id"));
        formData.append("item", id);
        apiClient.post("/member-api/setFavorite", formData)
          .then((res) => {
            //console.log(res);
            if (res.data.action === "delete") {
              //console.log("delete glob");
              setFavorites((prevFavorites) =>
                prevFavorites.filter((item) => item != id)
              );
            }
            resolve(true);
            //console.log("fav list updated glob - " ,favorites);

          })
          .catch((error) => {
            //console.log(error);
            if (error.response && error.response.status === 401) {
              //console.log('error.response: ',error.response)
              //logoutLocal();
              // window.location.replace("/members/login");
            }
            reject(false);

          });
      });
    }
  };

  useEffect(() => {
    setSColor(secondColor(location.pathname))
    setPColor(primaryColor(location.pathname))
    setTColor(thirdColor(location.pathname))
    //consol('loooccc', location)
    // if (location.pathname.includes('/trainings/')) 
  }, [location])

  useEffect(() => {
    // Fetch favorites when the component mounts
    if (localStorage.getItem("auth") && localStorage.getItem("roles") && localStorage.getItem("roles") == 'ROLE_PARTICIPANT') {
      // const formData = new FormData();
      //formData.append("user",   localStorage.getItem("id"));

      apiClient.get("/member-api/favoriteList_By_member")
        .then((result) => {
          console.log('fav list ', result);
          if (result.data.status === 2) {
            // console.log('fav list global == 2');
          } else {
            // console.log('fav list global');
            //  console.log(typeof result.data.favList,'---',result.data.favList);
            //  var myData = Object.keys(result.data.favList).map(key => { return result.data.favList[key];})
            // console.log(typeof myData,'---',myData);

            if (result.data.favList) setFavorites(result.data.favList);
          }
        })
        .catch((error) => {
          //console.log(error);
        });

      apiClient.get("/member-api/ordersList_By_member")
        .then((result) => {
          console.log('orderssss list ', result);
          setOrders(result.data.orderedTrainingIds);
          setOrdersSessions(result.data.orderedSessionIds);
        })
        .catch((error) => {
          //console.log(error);
        });

    } else {
      console.log('not logged in global');
    }

  }, []);


  const value = {
    favorites,
    orders,
    ordersSessions,
    checkFavorite,
    updateFavorites,
    removeFromFavorites,
    pColor,
    sColor,
    tColor
  };

  return (
    <FavoriteContext.Provider value={value}>
      {children}
    </FavoriteContext.Provider>
  );
};

export default FavoriteProvider;
