import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL_U } from "../../../config";
import axios from 'axios'
import { CCard, CCardBody, CCardFooter, CSpinner } from "@coreui/react";
import { Link } from "react-router-dom";
import TrainingCard from "./TrainingCard";

const Trainings = (props) => {
    const [formations, setFormations] = useState([]);
    // const [formations, setFormations] = useState([
    //     {
    //         "id": 1,
    //         "title": "formation",
    //         "createdAt": "2021-08-22T13:09:16+02:00",
    //         "updatedAt": "2021-08-22T13:09:16+02:00",
    //         "createdBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "updatedBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "sessions": [
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             }
    //         ],
    //         "slug": "formation",
    //         "IsActive": true,
    //         "description": "<p><strong>Thursday April 30, 2020 | 09:30 - 18:45 GMT+1 | in Tunis</strong></p><p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and hopefully temporary normal of staying home to protect our populations. With the current global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12 speakers from 10 different nationalities will provide a unique and special educational opportunity for clinicians of all levels. This will be one of the finest educational experiences, representing some of the most talented and gifted educators showcasing their amazing efforts. Please take this opportunity to join with us to expand our knowledge base and bring the world a bit closer together during these times of isolation.</p>",
    //         "images": [],
    //         "path": "formation-django-demo-6318c87bd9e39.mp4"
    //     },
    //     {
    //         "id": 1,
    //         "title": "formation",
    //         "createdAt": "2021-08-22T13:09:16+02:00",
    //         "updatedAt": "2021-08-22T13:09:16+02:00",
    //         "createdBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "updatedBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "sessions": [
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             }
    //         ],
    //         "slug": "formation",
    //         "IsActive": true,
    //         "description": "<p><strong>Thursday April 30, 2020 | 09:30 - 18:45 GMT+1 | in Tunis</strong></p><p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and hopefully temporary normal of staying home to protect our populations. With the current global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12 speakers from 10 different nationalities will provide a unique and special educational opportunity for clinicians of all levels. This will be one of the finest educational experiences, representing some of the most talented and gifted educators showcasing their amazing efforts. Please take this opportunity to join with us to expand our knowledge base and bring the world a bit closer together during these times of isolation.</p>",
    //         "images": [],
    //         "path": "formation-django-demo-6318c87bd9e39.mp4"
    //     },
    //     {
    //         "id": 1,
    //         "title": "formation",
    //         "createdAt": "2021-08-22T13:09:16+02:00",
    //         "updatedAt": "2021-08-22T13:09:16+02:00",
    //         "createdBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "updatedBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "sessions": [
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             }
    //         ],
    //         "slug": "formation",
    //         "IsActive": true,
    //         "description": "<p><strong>Thursday April 30, 2020 | 09:30 - 18:45 GMT+1 | in Tunis</strong></p><p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and hopefully temporary normal of staying home to protect our populations. With the current global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12 speakers from 10 different nationalities will provide a unique and special educational opportunity for clinicians of all levels. This will be one of the finest educational experiences, representing some of the most talented and gifted educators showcasing their amazing efforts. Please take this opportunity to join with us to expand our knowledge base and bring the world a bit closer together during these times of isolation.</p>",
    //         "images": [],
    //         "path": "formation-django-demo-6318c87bd9e39.mp4"
    //     },  
    //     {
    //         "id": 1,
    //         "title": "formation",
    //         "createdAt": "2021-08-22T13:09:16+02:00",
    //         "updatedAt": "2021-08-22T13:09:16+02:00",
    //         "createdBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "updatedBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "sessions": [
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             }
    //         ],
    //         "slug": "formation",
    //         "IsActive": true,
    //         "description": "<p><strong>Thursday April 30, 2020 | 09:30 - 18:45 GMT+1 | in Tunis</strong></p><p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and hopefully temporary normal of staying home to protect our populations. With the current global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12 speakers from 10 different nationalities will provide a unique and special educational opportunity for clinicians of all levels. This will be one of the finest educational experiences, representing some of the most talented and gifted educators showcasing their amazing efforts. Please take this opportunity to join with us to expand our knowledge base and bring the world a bit closer together during these times of isolation.</p>",
    //         "images": [],
    //         "path": "formation-django-demo-6318c87bd9e39.mp4"
    //     },  
    //     {
    //         "id": 1,
    //         "title": "formation",
    //         "createdAt": "2021-08-22T13:09:16+02:00",
    //         "updatedAt": "2021-08-22T13:09:16+02:00",
    //         "createdBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "updatedBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "sessions": [
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             }
    //         ],
    //         "slug": "formation",
    //         "IsActive": true,
    //         "description": "<p><strong>Thursday April 30, 2020 | 09:30 - 18:45 GMT+1 | in Tunis</strong></p><p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and hopefully temporary normal of staying home to protect our populations. With the current global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12 speakers from 10 different nationalities will provide a unique and special educational opportunity for clinicians of all levels. This will be one of the finest educational experiences, representing some of the most talented and gifted educators showcasing their amazing efforts. Please take this opportunity to join with us to expand our knowledge base and bring the world a bit closer together during these times of isolation.</p>",
    //         "images": [],
    //         "path": "formation-django-demo-6318c87bd9e39.mp4"
    //     },
    //     {
    //         "id": 1,
    //         "title": "formation",
    //         "createdAt": "2021-08-22T13:09:16+02:00",
    //         "updatedAt": "2021-08-22T13:09:16+02:00",
    //         "createdBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "updatedBy": {
    //             "email": "amira@dozane-studio.com"
    //         },
    //         "sessions": [
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             },
    //             {
    //                 "IsActive": true
    //             }
    //         ],
    //         "slug": "formation",
    //         "IsActive": true,
    //         "description": "<p><strong>Thursday April 30, 2020 | 09:30 - 18:45 GMT+1 | in Tunis</strong></p><p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and hopefully temporary normal of staying home to protect our populations. With the current global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12 speakers from 10 different nationalities will provide a unique and special educational opportunity for clinicians of all levels. This will be one of the finest educational experiences, representing some of the most talented and gifted educators showcasing their amazing efforts. Please take this opportunity to join with us to expand our knowledge base and bring the world a bit closer together during these times of isolation.</p>",
    //         "images": [],
    //         "path": "formation-django-demo-6318c87bd9e39.mp4"
    //     },
    // ]);
    //"assets/images/home3.png"
    const banners = [
        {
            category: 3,
            path: "/assets/images/home3.png",
            alt: "gdtc banner"
        },
        {
            category: 4,
            path: "/assets/images/home3.png",
            alt: "gdtc banner"
        },
        {
            category: 5,
            path: "/assets/images/informatique2.jpg",
            alt: "gdtc banner"
        },
        {
            category: 6,
            path: "/assets/images/banner-styliste.jpg",
            alt: "gdtc banner"
        },
        {
            category: 7,
            path: "/assets/images/banner-menuisier.jpg",
            alt: "gdtc banner"
        },
        {
            category: 8,
            path: "/assets/images/banner-jornaliste.jpg",
            alt: "gdtc banner"
        },
        {
            category: 9,
            path: "/assets/images/banner-enfant-menuisier.jpg",
            alt: "gdtc banner"
        },
        {
            category: 10,
            path: "/assets/images/banner-artisant.jpg",
            alt: "gdtc banner"
        },
    ]
    const [Banner, setBanner] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        setIsLoaded(false)
        axios(`${API_BASE_URL}/trainings/${id}`)
            .then((res) => res.data)
            .then(
                (result) => {
                    console.log('tttrrrr', result);
                    setFormations(result.trainings);
                    if (result.banner) setBanner(IMAGES_BASE_URL_U + result.banner)
                    setIsLoaded(true);
                    console.log(result)
                },
                (error) => {
                    console.log(error);
                    setError(error)
                }
            );
        //request banner from backend
        console.log('ggggggggg');
        console.log(banners.find(item => item.category == id));
        setBanner(banners.find(item => item.category == id).path);
    }, [id]);

    if (error) {
        return (
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
                <div className="row">
                    {error.message}
                </div>
            </div>
        );
    }
    // else 
    // if (!isLoaded) {
    //     return (
    //         <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
    //             <div className="row">
    //             <h2 className=" text-center heading">COURSES</h2>
    //             <div className='loadingMain'>
    //   <CSpinner color="default" size="sm" />
    // </div>
    //             </div>
    //             </div>
    //     );
    // } 
    else {

        return (
            <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%", marginTop: "2" }}>
                <div className="row">
                    {Banner && <div className='fullBanner fullBanner2'><img src={Banner} alt='gdtc home banner' /></div>}

                    <h2 className=" text-center heading">COURSES</h2>

                    {isLoaded && formations.length > 0 ? formations.map((formation, index) => {
                        return (
                            <div className="col-md-3" key={index} style={{ marginBottom: '20px', borderRadius: '50px' }} >
                                <TrainingCard
                                    path={formation.demo && formation.demo}
                                    image={formation.imageTrainings && formation.imageTrainings.length > 0 ? formation.imageTrainings[0].path : ''}
                                    title={formation.title}
                                    formateur={formation.trainer ?
                                        formation.trainer.firstName && formation.trainer.firstName + " " + formation.trainer.lastName
                                        : ''}
                                    createdAt={formation.createdAt}
                                    updatedAt={formation.updatedAt}
                                    sessions={formation.sessions}
                                    id={formation.id}
                                    language={'English'}
                                    medical={false}
                                >

                                </TrainingCard>
                            </div>

                        )
                    })
                        : isLoaded && formations.length < 1 ?
                            "no course here yet"
                            : <div className='loadingMain'>
                                <CSpinner color="default" size="sm" />
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default Trainings
