import React, { useState, useEffect } from 'react';
import GeneralContext from './GeneralContext';
import { API_BASE_URL } from 'src/config';
import apiClient from './apiClient';
import axios from 'axios';

const GeneralProvider = ({ children }) => {
  const [listTrainer, setListTrainer] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [listDomaines, setListDomaines] = useState([]);



  useEffect(() => {
    console.log('in provider')
    if (localStorage.getItem("auth") && localStorage.getItem("roles")
      && (localStorage.getItem("roles") == 'ROLE_ADMIN' || localStorage.getItem("roles") == 'ROLE_MANAGER')
    )
      apiClient(`/formateurs`)
        .then((res) => res.data)
        .then(
          (result) => {
            console.log("result uuuuffff: ", result)
            setListTrainer(result)
          },
          (error) => {
            console.log(error);

          }
        );
    axios.get(`${API_BASE_URL}/categories-trainings`).then((res) => {
      setListCategory(res.data)
    });

    axios.get(`${API_BASE_URL}/specialtys`)
      .then((result) => {
        console.log('speccccc list ', result);
        setListDomaines(result.data)
      })
      .catch((error) => {
        console.log(error);
      });

  }
    , [])

  const value = {
    listTrainer,
    listCategory,
    listDomaines
  };

  return (

    <GeneralContext.Provider value={value}>
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralProvider;
