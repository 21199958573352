//import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import React, { useState, useEffect, useContext } from "react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  CButton, CCol,
  CCard, CCardBody, CCardFooter,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { MEDIA_URL } from "../../../config";
import Moment from "react-moment";
import FavoriteContext from "../FavoriteContext";
import { isLogin } from "src/components/middleware/auth";

const TrainingCard = (props) => {

  const [showShare, setShowShare] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=https://gdtc.ac/test-5436726732/GMTC-medical-center/${props.id}`;
  const whatsappLink = `https://wa.me/11234567890?text=${encodeURIComponent(`Check this course https://gdtc.ac/test-5436726732/GMTC-medical-center/${props.id}`)}`;


  const [isFav, setIsFav] = useState(null);
  const [isBeating, setBeating] = useState(false);
  const [modal, setModal] = useState(false);
  const { favorites, updateFavorites, checkFavorite } = useContext(
    FavoriteContext
  );
  const setFavourite = async () => {
    console.log('in set ', isLogin())

    if (!isLogin()) setModal(true);
    else {
      setBeating(true);
      try {
        const success = await updateFavorites(props.id);
        console.log('successxxxxxvvvv ', success);
        if (success && success == 'log') {
          setModal(true);
        }
        if (success && success != 'log') {
          setIsFav(true);
          setBeating(false);
        } else {
          setIsFav(false);
          setBeating(false);
        }
      } catch (error) {
        console.error(error);
        setBeating(false);
      }
    }
  };

  useEffect(() => {
    // console.log('ch',checkFavorite(props.id) , favorites)
    if (favorites) {
      setIsFav(checkFavorite(props.id));

    }
  }, [favorites]);


  const handleErrorImg = () => {
    setErrorImg(true)
  };

  return (
    <>
      <CCard style={{ borderRadius: '10px' }}>
        {props.path ? <video autoPlay="autoplay" controls muted style={{ borderRadius: '10px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', }}>
          <source src={MEDIA_URL + props.path} type="video/mp4" />
        </video>
          : <div className="imgContainer">
            {props.image ?
              <img src={MEDIA_URL + props.image} onError={handleErrorImg} />
              : <img className="defaultImg" src='/assets/logo-gdtc.png' onError={handleErrorImg} />
            }
          </div>}
        <CCardBody className="courseCardTraining">
          {
            <div className={`sharerSection ${showShare ? 'show' : 'hide'}`}>
              <div onClick={() => { console.log('shareee'); setShowShare(false); }} className="closeShare">x</div>
              <div>
                <a style={{ marginRight: "0px" }} rel="noopener" role="img" aria-label="Facebook Link" href={facebookLink} target="_blank" class="lb_socialicon cc-fb w-inline-block"></a>
                <a className="sharingButton wtsap" href={whatsappLink} target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" class="bi bi-whatsapp" viewBox="0 0 16 16"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" fill="black"></path> </svg>
                </a>
              </div>

            </div>}
          <h3 className="oneLineOnly2">
            {props.title && props.title.length > 38
              ? props.title.substr(0, 34) + "..."
              : props.title}</h3>
          <div className="row">
            <div className="col-md-4">
              <label className="text-center" htmlFor="title"> Formateur</label>
              <p className="text-center price oneLineOnly">{props.formateur} </p>
            </div>
            <div className="col-md-4">
              <label className="text-center" htmlFor="title"> Date de début</label>
              <p className="text-center price"> <Moment format="DD-MM-YYYY">
                {props.createdAt}
              </Moment> </p>
            </div>
            <div className="col-md-4">
              <label className="text-center" htmlFor="title">{
                props.sessions && props.sessions.length && props.sessions.length > 0 ? "Les sessions" : ""} </label>
              {props.sessions && props.sessions.length && props.sessions.length > 0
                ? <p className="text-center price">{props.sessions.length} </p>
                : <p className="text-center price">Aucun session </p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label className="text-center" htmlFor="title"> Language</label>
              <p className="text-center price">English </p>
            </div>
            <div className="col-md-4">
              <label className="text-center" htmlFor="title"> Last update</label>
              <p className="text-center price"><Moment format="DD-MM-YYYY">
                {props.updatedAt}
              </Moment> </p>
            </div>
            <div className="col-md-4 shareButton" >
              <div onClick={() => { console.log('shareee'); setShowShare(!showShare); }}
                style={{ width: "fit-content", textAlign: "center", margin: "auto" }}
              >    <label style={{ cursor: "pointer" }} className="text-center iconInCourseCard" htmlFor="title"> share
                </label>
                <p className="text-center price iconInCourseCard"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" id="mainIconPathAttribute"></path>
                </svg> </p></div>
            </div>
          </div>
        </CCardBody>
        <CCardFooter>
          <Link to={props.medical ? `/GMTC-medical-center/trainings/${props.id}`
            : `/trainings/${props.id}`
          } className="btn_black w-button btn-block readMoreButton" > Read more</Link>
          <div
            onClick={
              isBeating ? () => { }
                : () => {
                  setFavourite();
                }
            }
            className={`favorite favIconClass ${isBeating ? 'beating' : ''}`}
          //disabled={isFav == null? true : false}
          >
            {
              !isLogin() ?
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                  style={{ display: "block", fill: "#c4c4c400", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                  <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>
                : isLogin() && isFav == null ? ''
                  : isFav == false ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                      style={{ display: "block", fill: "#c4c4c400", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                      <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>

                    : isFav == true ?
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                        style={{ display: "block", fill: "#f10621", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                        <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>

                      : ''}
          </div>


        </CCardFooter>
      </CCard>

      <CModal visible={modal} onClose={() => setModal(!modal)}>
        <CModalHeader closeButton></CModalHeader>
        <CModalBody>Vous devez vous connecter d'abord</CModalBody>
        <CModalFooter>
          <Link className="btn-primary btn " to="/login">
            Login
          </Link>
          <CButton color="secondary" onClick={() => setModal(false)}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
export default TrainingCard;
