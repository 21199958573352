import React, { useEffect, useState, useContext } from "react";

import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../../reducers/actions';
import Moment from "react-moment";
import {
  CAccordion,
  CAccordionBody,
  CAccordionButton,
  CAccordionCollapse,
  CAccordionHeader,
  CAccordionItem,
  CCardHeader,
  CCarousel,
  CCarouselIndicators,
  CCarouselInner,
  CCarouselItem,
  CCarouselCaption,
  CCarouselControl,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CImage,
  CSpinner

} from "@coreui/react";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { MEDIA_URL, consol, primaryColor, secondColor, thirdColor } from "../../../config";
//import { Fade } from "react-slideshow-image";
import FavoriteContext from "../FavoriteContext";
import { isLogin } from "src/components/middleware/auth";
import apiClient from "src/components/apiClient";

const Course = (props, state) => {
  const [formation, setFormation] = useState([]);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorCart, setErrorCart] = useState(false);
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [alreadyInCartSessions, setAlreadyInCartSessions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [submittedSession, setSubmittedSession] = useState(null);
  const [activeKey, setActiveKey] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalExams, setTotalExams] = useState(0);
  const [click, setClick] = useState(false);
  const [typeBuying, setTypeBuying] = useState("");
  const [readModule, setReadModule] = useState(false);

  const [moduleTitle, setModuleTitle] = useState("");
  const [moduleDescription, setModuleDescription] = useState("");
  const [modulePrice, setModulePrice] = useState("");
  const [moduleDuration, setModuleDuration] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [visibleLoginAlert, setVisibleLoginAlert] = useState(false);
  const [test, setTest] = useState(false);
  const [cart, setCart] = useState([]);

  const [isFav, setIsFav] = useState(null);
  const [isBeating, setBeating] = useState(false);
  const [modal, setModal] = useState(false);

  const { favorites, updateFavorites, checkFavorite, orders, ordersSessions, pColor, sColor, tColor } = useContext(
    FavoriteContext
  );
  const setFavourite = async () => {
    console.log('in set ', isLogin())

    if (!isLogin()) setModal(true);
    else {
      setBeating(true);
      try {
        const success = await updateFavorites(props.id);

        if (success && success == 'log') {
          setModal(true);
        }
        if (success && success != 'log') {
          setIsFav(true);
          setBeating(false);
        } else {
          setIsFav(false);
          setBeating(false);
        }
      } catch (error) {
        console.error(error);
        setBeating(false);
      }
    }
  };

  useEffect(() => {
    // console.log('ch',checkFavorite(props.id) , favorites)
    if (favorites) {
      setIsFav(checkFavorite(props.id));

    }
  }, [favorites]);


  useEffect(() => {
    console.log('basketttttttt zzzzz', props.basketItems);
    if (props.basketItems.sessions) setAlreadyInCartSessions(props.basketItems.sessions)
    if (props.basketItems.trainings) {
      console.log(props.basketItems.trainings)
      if (props.basketItems.trainings.find(el => el == id)) {
        console.log('yessss')
        setAlreadyInCart(true)
      } else console.log('noooo')

    }
  }, [props.basketItems]);

  const testformation = {
    "id": 1,
    "title": "Rétinopathie diabétique",
    "createdBy": {
      "id": 1,
      "email": "amira@dozane-studio.com",
      "username": "amira@dozane-studio.com",
      "roles": [
        "ROLE_ADMIN"
      ],
      "firstName": "Ahmed",
      "lastName": "Sherif",
      "profession": "Doctor",
    },
    "sessions": [
      {
        "id": 1,
        "title": "Description de la rétinopathie diabétique",
        "training": 1,
        "modules": [{ id: 1, title: "module 1", price: 5 }, { id: 2, title: "module 2", price: 5 }],
        "IsActive": true
      },
      {
        "id": 2,
        "title": "Conséquences de la rétinopathie diabétique",
        "training": 1,
        "modules": [{ id: 1, title: "module 1", price: 5 }, { id: 2, title: "module 2", price: 5 }],
        "IsActive": true
      },
      {
        "id": 3,
        "title": "Facteurs de risque de la rétinopathie diabétique",
        "training": 1,
        "modules": [{ id: 1, title: "module 1", price: 5 }, { id: 2, title: "module 2", price: 5 }],
        "IsActive": true
      }
      ,
      {
        "id": 4,
        "title": "Traitements de la rétinopathie diabétique",
        "training": 1,
        "modules": [{ id: 1, title: "module 1", price: 5 }, { id: 2, title: "module 2", price: 5 }],
        "IsActive": true
      },
      {
        "id": 5,
        "title": "Prévention et dépistage de la rétinopathie",
        "training": 1,
        "modules": [{ id: 1, title: "module 1", price: 5 }, { id: 2, title: "module 2", price: 5 }],
        "IsActive": true
      }
    ],
    "slug": "formation",
    "path": "formation-demo-6122305ce1d88.mp4",
    "file": null,
    "language": null,
    "category": {
      "isActive": true,
      "id": 1,
      "label": "categrie1",
      "slug": "categrie1",
      "trainings": [
        1
      ],
    },
    "sessions": [
      {
        "id": 1,
        "title": "Description de la rétinopathie diabétique",
        "training": 1,
        "modules": [],
        "IsActive": true
      },
      {
        "id": 2,
        "title": "Conséquences de la rétinopathie diabétique",
        "training": 1,
        "modules": [],
        "IsActive": true
      },
      {
        "id": 3,
        "title": "Facteurs de risque de la rétinopathie diabétique",
        "training": 1,
        "modules": [],
        "IsActive": true
      }
      ,
      {
        "id": 4,
        "title": "Traitements de la rétinopathie diabétique",
        "training": 1,
        "modules": [],
        "IsActive": true
      },
      {
        "id": 5,
        "title": "Prévention et dépistage de la rétinopathie",
        "training": 1,
        "modules": [],
        "IsActive": true
      }
    ],
    "IsActive": true,
    "description": "<p>Description de la rétinopathie diabétique</p><p> Conséquences de la rétinopathie diabétique</p><p>Facteurs de risque de la rétinopathie diabétique</p><p>Traitements de la rétinopathie diabétique</p> <p>Prévention et dépistage de la rétinopathie</p>",
    "images": []
  }

  const { id } = props.match.params;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=https://gdtc.ac/test-5436726732/GMTC-medical-center/trainings/${id}`;
  const whatsappLink = `whatsapp://send?text=Check this course https://gdtc.ac/test-5436726732/GMTC-medical-center/trainings/${id}`;

  useEffect(() => {
    console.log("props");
    console.log(props);
    console.log('orders', orders, ordersSessions);

    axios(`${API_BASE_URL}/formation/${id}`)
      .then((res) => res.data)
      .then(
        (result) => {
          console.log('trrrrrnnnnnn', result)
          setFormation(result);
          if (result.sessions) setSessionSelected(result.sessions[0]);
          let duration = 0;
          let exams = 0;
          for (let index = 0; index < result.sessions.length; index++) {
            if (sessionTotalDuration(result.sessions[index])) duration = duration + sessionTotalDuration(result.sessions[index]);
            exams = exams + sessionTotalExams(result.sessions[index]);
            if (index == (result.sessions.length - 1)) {
              setTotalDuration(Math.round((duration) * 10) / 10);
              setTotalExams(exams);
            }
          }
          //setFormation(testformation);
          if (result.id)
            if (localStorage.getItem('auth')) {
              //const formData = new FormData();
              //formData.append("id", localStorage.getItem('id'));
              //axios.post(`${API_BASE_URL}/participants/profile`, formData)
              apiClient.get('/member-api/profile')
                .then((response) => {
                  console.log("user");
                  console.log(response);
                  //       if (response.data.cart) {
                  //       setCart(response.data.cart);
                  //     let cart = response.data.cart;
                  //     let temp = [];
                  //    let obj = cart.find(o => o.id == result.id);
                  //    console.log('obj: ',obj);
                  //    if(obj && obj.type == "training") setAlreadyInCart(true);
                  //    for (let i=0; i < cart.length; i++) {
                  //     if (cart[i].type == "session") {
                  //         temp.push(cart[i].id);
                  //     }
                  //     setAlreadyInCartSessions(temp);
                  // }

                  //       } else {

                  //       }
                })
                .catch((response) => {
                  console.log(response);
                });
            }


          setIsLoaded(true);
        },
        (error) => {
          console.log(error.message, '----', error);
          setError(error)
        }
      );

  }, []);


  const trainingTotalPrice = () => {
    let total = 0;
    for (var i = 0; i < formation.sessions.length; i++) {
      for (var j = 0; j < formation.sessions[i].modules.length; j++) {
        total = total + formation.sessions[i].modules[j].price;
      }
    }
    return total;
  }

  const sessionTotalPrice = (session) => {
    let total = 0;
    for (var j = 0; j < session.modules.length; j++) {
      total = total + session.modules[j].price;
    }
    return total;
  }

  const sessionTotalExams = (session) => {
    let total = 0;
    for (var j = 0; j < session.modules.length; j++) {
      if (session.modules[j].type == 'test') ++total;
    }
    // console.log('exams func: ',total);
    return total;
  }

  const sessionTotalDuration = (session) => {
    let total = 0;
    for (var j = 0; j < session.modules.length; j++) {
      if (session.modules[j].duration) total = total + session.modules[j].duration;
    }
    return total;
  }

  const listModules = () => {
    let total = [];
    for (var i = 0; i < formation.sessions.length; i++) {
      for (var j = 0; j < formation.sessions[i].modules.length; j++) {
        total.push(formation.sessions[i].modules[j]);
      }
    }
    return total;
  }

  const listSession = () => {
    let total = [];
    for (var i = 0; i < formation.sessions.length; i++) {
      total.push(formation.sessions[i]);
    }
    return total;
  }

  //   const addToCart = () => {
  // console.log("addtocart");

  //     setClick(true);
  //     if(price){
  //       const training ={
  //         id: formation.id,
  //         title: formation.title,
  //         price : price,
  //       }
  //       props.addToBasket({training});
  //       props.history.push("/basket");
  //     }
  //   }

  const changeType = (value) => {
    setTypeBuying(value);
  };

  const addToCart = () => {
    setSubmittedSession(null);
    if (localStorage.getItem('auth')) {
      setSubmitted(true);
      console.log('formation.id ', formation.id)

      props.addToBasketTraining(formation.id).then(success => {
        console.log('success ? ', success)
        if (!success) {
          setErrorCart(true);
          setSubmitted(false);
          setVisibleLoginAlert(true);
        } else {
          setErrorCart(false);
          setSuccess(true);
          setSubmitted(false);
        }
      }).catch(() => {
        setSubmitted(false);
        setErrorCart(true);
        setVisibleLoginAlert(true);
      });
    } else {
      setVisibleLoginAlert(true);

    }
  };

  const addToCartSession = (idS) => {
    setSubmittedSession(null);
    if (localStorage.getItem('auth')) {
      setSubmitted(true);
      setSubmittedSession(idS);
      props.addToBasketSession(idS).then(success => {
        console.log('success ? ', success)
        if (!success) {
          setErrorCart(true);
          setSubmitted(false);
          setVisibleLoginAlert(true);
        } else {
          setErrorCart(false);
          setSuccess(true);
          setSubmitted(false);
        }
      }).catch(() => {
        setErrorCart(true);
        setSubmitted(false);
        setVisibleLoginAlert(true);
      });


    }
    else {
      setVisibleLoginAlert(true);
    }

  }

  const onClickBuyPerSession = () => {
    console.log('alreadyInCartSessions', alreadyInCartSessions)
    if (alreadyInCart) { alert('you have already added the whole formation to cart') }
    else if (orders.includes(formation.id)) { alert('you have already ordered the whole formation') }
    else if (document.getElementById("sessionsListId")) { setTypeBuying("session"); document.getElementById("sessionsListId").scrollIntoView(); }
  }

  const buyNow = () => {
    console.log(submittedSession);
    console.log(alreadyInCartSessions);
    console.log(alreadyInCartSessions.findIndex(el => el == 3));
  }

  if (error) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div>Erreur : {error.message}</div>
          </div>
        </main>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div className="container">
        <main className="l-main">
          <div className="l-section l-section--archive">
            <div className='loadingMain'>
              <CSpinner color="default" size="sm" />
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className="text-center w_commercial_alternating_cards cc_text_group_7" >

        <CModal alignment="center" visible={visibleLoginAlert} onClose={() => setVisibleLoginAlert(false)}>
          <CModalHeader>
            <CModalTitle>
              Alerte
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <>
              <br />
              {!localStorage.getItem('auth') && "vous devez vous connecter avant d'ajouter au panier"
              }
              {errorCart && " error"}
              <br />
            </>
          </CModalBody>
          <CModalFooter>

            <CButton color="secondary" onClick={() => setVisibleLoginAlert(false)}> OK </CButton>


          </CModalFooter>
        </CModal>



        <div className="row coursePage">
          <div className="col-md-9">
            <video autoPlay="autoPlay" width="100%" controls muted style={{ borderRadius: '5px' }} key={formation.demo + formation.id}>
              <source src={MEDIA_URL + formation.demo} type="video/mp4" key={formation.demo + formation.id} />
            </video>
          </div>
          <div className="col-md-3 text-center cartSectionCoursePage">
            <CCardHeader style={{ height: "99%", borderRadius: "5px", position: "relative", backgroundColor: tColor }}>
              <div className={`sharerSection2 sharerSection ${showShare ? 'show' : 'hide'}`} style={{ backgroundColor: pColor }}>
                <div onClick={() => { console.log('shareee'); setShowShare(false); }} className="closeShare">x</div>
                <div>
                  <a style={{ marginRight: "0px" }} rel="noopener" role="img" aria-label="Facebook Link" href={facebookLink} target="_blank" className="lb_socialicon cc-fb w-inline-block"></a>
                  <a className="sharingButton wtsap" href={whatsappLink} target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" className="bi bi-whatsapp" viewBox="0 0 16 16"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" fill="black"></path> </svg>
                  </a>
                </div>

              </div>
              <br />
              <div className="text-center">
                {formation.free ? '' :
                  <p className="text-center" style={{ fontWeight: "400px" }}>Prix de la formation <strong style={{ fontSize: "19px" }}>{formation.price} </strong> <strong style={{ fontSize: "13px" }}>DNT</strong></p>
                }
                <p className="text-center">

                  {/* {trainingTotalPrice()} */}
                </p>

                {formation.free ?
                  <> <br />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }} >This is a free course </span> <br /> <br />
                    <Link to={`/course/${formation.id}/${formation.slug}`} className="btn_black w-button btn-block " style={{ backgroundColor: pColor, borderColor: pColor }}>
                      Start the free course
                    </Link> </>
                  : alreadyInCart ?
                    <span className="btn_black w-button btn-block disabledButton " style={{ backgroundColor: pColor, borderColor: pColor }}> Déjà dans panier &#10003;
                    </span>
                    : orders.includes(formation.id) ? <span className="btn_black w-button btn-block disabledButton" style={{ backgroundColor: pColor, borderColor: pColor }}> Déjà commandé &#10003;
                    </span>
                      : submitted ? <span className="btn_black w-button btn-block " style={{ backgroundColor: pColor, borderColor: pColor, cursor: "default" }}> Ajouter au panier  {!submittedSession && <CSpinner color="default" size="sm" />}
                      </span>
                        : <span className="btn_black w-button btn-block " onClick={() => { addToCart() }} style={{ backgroundColor: pColor, borderColor: pColor }}> Ajouter au panier <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" fill="white"></path></svg>
                        </span>
                }
                {/* {submitted && <span className="btn_black w-button btn-block " style={{ backgroundColor: "#0038e1",borderColor: "#0038e1", cursor:"default"}}> Ajouter au panier  <CSpinner color="default" size="sm" />
                   </span>} */}
                {
                  formation.free ? ''
                    :
                    <>
                      <span className={`btn_black w-button btn-block ${alreadyInCart || orders.includes(formation.id) ? 'disabledButton' : ''} `} onClick={() => {
                        alreadyInCart || orders.includes(formation.id) ? '' : buyNow()
                      }} style={{ backgroundColor: "#fff", borderColor: "#fff", color: "black" }}> Acheter maintenant</span>
                      <span className={`btn_black w-button btn-block ${alreadyInCart || orders.includes(formation.id) ? 'disabledButton' : ''} `} onClick={() =>
                        alreadyInCart || orders.includes(formation.id) ? '' : onClickBuyPerSession()
                      } key='session'>Acheter par session</span>
                    </>
                }
                {/* <span className="btn_black w-button btn-block "  onClick={() => setTypeBuying("module")} key='module'>Acheter par module</span> */}
                <br />
                <div>
                  <p style={{ marginBottom: "10px" }}> Ce cours contient :</p>
                  <ul><li>
                    <img src="/assets/icons/infinity-loop-icon.svg" />
                    Accès illimité </li>
                    <li>
                      <img src="/assets/icons/certificate-icon.svg" />
                      Certificat Homologué</li>
                    {totalDuration > 0 && <li>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-play-btn" viewBox="0 0 16 16"> <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" /> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" /> </svg>
                      {totalDuration} minutes de formation </li>
                    }
                    {totalExams > 0 && <li>
                      <img src='/assets/icons/test-icon.svg' />
                      {totalExams} examens</li>}
                  </ul>
                </div>
                <div className="coursePageCartfooter">
                  <div className="coursePageCartfooter1" onClick={() => {
                    console.log('shareee');
                    setShowShare(!showShare);
                  }}
                  // style={{width:"fit-content",textAlign:"center",margin:"auto"}}
                  > <p style={{ cursor: "pointer" }} className="text-center price iconInCourseCard">partager <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-share" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" id="mainIconPathAttribute"></path>
                  </svg> </p></div>
                  <div className="favorite price " style={{ color: "black", lineHeight: "unset", float: "right" }}>favoris

                    <div
                      onClick={
                        isBeating ? () => { }
                          : () => {
                            setFavourite();
                          }
                      }
                      className={`favorite favIconClass2 ${isBeating ? 'beating' : ''}`}
                    //disabled={isFav == null? true : false}
                    >
                      {
                        !isLogin() ?
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                            style={{ display: "block", fill: "#c4c4c400", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                            <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>
                          : isLogin() && isFav == null ? ''
                            : isFav == false ?
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                                style={{ display: "block", fill: "#c4c4c400", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                                <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>

                              : isFav == true ?
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false"
                                  style={{ display: "block", fill: "#f10621", stroke: "#e2001a", strokeWidth: "2", overflow: "visible" }}>
                                  <path d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z"></path></svg>

                                : ''}
                    </div>

                  </div>

                </div>

              </div>
              <br />
              <br />
            </CCardHeader>
          </div>
        </div>

        <br />
        <br />
        {/* <div className="col-md-12">mmmmmm
                  <CAccordion>
                    {listModules() && listModules().map((module,index) => {
                        return (
                          <CAccordionItem key={index+1}>
                            <CAccordionHeader>
                              <CAccordionButton collapsed={activeKey !== index+1} onClick={() => activeKey === index+1 ? setActiveKey(0) : setActiveKey(index+1)}>
                                {module.title}
                              </CAccordionButton>
                            </CAccordionHeader>
                            <CAccordionCollapse visible={activeKey === index+1}>
                              <CAccordionBody>

                                <p> Duration : <b>{module.duration}</b> </p>
                                <p> Prix du module : <b>{module.price}</b> DTN </p>
                                <p className="primary"
                                  style={{color :'primary', backgroundColor:'primary',cursor: 'pointer'}} 
                                  onClick={() => { 
                                    setModuleTitle(module.title),
                                    setModuleDescription(module.description),
                                    setModulePrice(module.duration),
                                    setModuleDuration(module.duration),
                                    setReadModule(true)
                                  }}>
                                    Lire la suite
                                </p>
                                <br/><br/>
                                <span className="btn_black w-button btn-block " 
                                  onClick={() => { addToCart() }} 
                                  style={{ borderRadius: "5px" }}>
                                    Add to cart 
                                </span>
                              </CAccordionBody>
                            </CAccordionCollapse>
                          </CAccordionItem>
                        )
                    })}
                  </CAccordion>
                </div> */}
        <div className="row col-md-12 text-center courseDetails" style={{ margin: 'auto', }}>
          <div className={(!formation.imageTrainings) || (formation.imageTrainings && formation.imageTrainings.length == 0) ? 'col-md-12' : 'col-md-6'} style={{ textAlign: "left" }}>
            <div className="courseDetailsCol6" style={{ padding: "15px", backgroundColor: tColor }}>
              <h3 style={{ textTransform: "capitalize" }}><p>{formation.title}</p></h3>
              {formation.trainer && <Link style={{ textTransform: "capitalize" }} to={`/trainer/${formation.trainer.id}/${formation.trainer.firstName}_${formation.trainer.lastName}`} className="itemCardLink">
                <h5 >By {formation.trainer.firstName + " " + formation.trainer.lastName}</h5>
              </Link>}
              <h5 >Created at  <Moment format="DD-MM-YYYY">
                {formation.createdAt}
              </Moment>
              </h5>
              {formation.description && <h6><strong className="sectionTitleCoursePage">Description:</strong>
                <p dangerouslySetInnerHTML={{ __html: formation.description }}></p>
              </h6>}
              {formation.prereq && <h6><strong className="sectionTitleCoursePage">Requirements:</strong>
                <div>
                  <ul>
                    {(formation.prereq.split("|")).map((el, index) => {
                      return (
                        <li key={index}>
                          {el}
                        </li>

                      );
                    })}
                  </ul>
                </div > </h6>}
              {formation.goals && <h6><strong className="sectionTitleCoursePage">This course is intended for:</strong>
                <div>
                  <ul>
                    {(formation.goals.split("|")).map((el, index) => {
                      return (
                        <li key={index}>
                          {el}
                        </li>

                      );
                    })}
                  </ul>
                </div>
              </h6>}
              <br />

            </div>

          </div>
          <div className="col-md-6 courseDetailsMedia" style={{ textAlign: "left", paddingLeft: "40px" }}>
            {formation.imageTrainings && formation.imageTrainings.length > 0 &&
              <div className="row">

                <CCarousel controls indicators className="courseSlides">
                  {formation.imageTrainings.map((image, index) => {
                    return (
                      image.path &&
                      <CCarouselItem key={index}>
                        <CImage className="d-block w-100"
                          key={index}
                          src={MEDIA_URL + image.path}
                        />
                      </CCarouselItem>
                    )
                  })}
                </CCarousel>
              </div>
            }

          </div>



        </div>
        <br />

        <div className="row col-md-12 text-center courseDetails" style={{ marginTop: '20px', }}>
          <div className="col-md-12" style={{ textAlign: "left" }}>
            <h3>Sessions :</h3>
          </div>
          <div className="col-md-12" style={{ textAlign: "left" }}>
            <CAccordion>
              {listSession() && listSession().map((session, index) => {
                return (
                  <CAccordionItem key={index + 1} id="sessionsListId">
                    <CAccordionHeader>
                      <CAccordionButton style={typeBuying == "session" && session.modules && session.modules.length > 0 ? { width: '75%' } : {}} className="accordionTitleCart" collapsed={activeKey !== index + 1} onClick={() => { activeKey === index + 1 ? setActiveKey(0) : setActiveKey(index + 1); setSessionSelected(session) }}>
                        {session.title}
                      </CAccordionButton>
                      {typeBuying == "session" && session.modules && session.modules.length > 0 &&
                        <>
                          {/* <span className="addToCartInAccordion btn_black w-button btn-block " 
                                    onClick={() => { addToCartSession(session.id,session.title,session.price) }} 
                                    style={{ borderRadius: "5px" }}>
                                      Add to cart 
                                  </span> */}
                          {
                            alreadyInCartSessions && alreadyInCartSessions.length > 0 && alreadyInCartSessions.find(el => el == session.id) ?
                              <span className="addToCartInAccordion btn_black w-button btn-block "
                                style={{ borderRadius: "5px", cursor: "default" }}> Déjà dans panier &#10003;
                              </span>
                              : ordersSessions.includes(session.id) ? <span className="addToCartInAccordion btn_black w-button btn-block "
                                style={{ borderRadius: "5px", cursor: "default" }}> Déjà commandé &#10003;
                              </span>
                                : submitted ? <span className="addToCartInAccordion btn_black w-button btn-block "
                                  style={{ borderRadius: "5px", cursor: "default" }}> Ajouter au panier  {submittedSession && submittedSession == session.id && <CSpinner color="default" size="sm" />}
                                </span>
                                  : <span className="addToCartInAccordion btn_black w-button btn-block "
                                    style={{ borderRadius: "5px" }}
                                    onClick={() => { addToCartSession(session.id) }}
                                  >

                                    Ajouter au panier  <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" fill="white"></path></svg>
                                  </span>
                          }

                        </>

                      }
                    </CAccordionHeader>
                    <CAccordionCollapse visible={activeKey === index + 1}>
                      <CAccordionBody>
                        {/* <p> Nombre du modules : <b>{session.modules.length}</b> </p> */}
                        {/* {session.modules && session.modules.length>0 &&
                                  <>
                                    <p> Duration du session : <b>{sessionTotalDuration(session)}</b> minutes</p> 
                                    <p> Prix du session : <b>{session.price}</b> $ </p>
                                  </>
                                }

                              <ul>
                                {session.modules && session.modules.length>0 &&
                                  session.modules.map((module, indexModule) => {
                                    return (
                                      <li>{module.title}</li>
                                    )
                                  })
                                }
                              </ul> */}
                        <div className="courseDetailsCol6" style={{ padding: "15px", backgroundColor: tColor }}>
                          <h3 style={{ textTransform: "capitalize" }}><p>{session.title}</p></h3>
                          {session.description && session.description != 'null' &&
                            <h6><strong className="sectionTitleCoursePage">Description:</strong>
                              <p dangerouslySetInnerHTML={{ __html: session.description }}></p>
                            </h6>}
                          {session.modules && session.modules.length > 0 &&
                            <div className="row col-md-12 text-center courseDetails">
                              <div className="col-md-6 courseDetailsAccorUl" style={{ textAlign: "left", paddingLeft: "40px" }}>

                                <ul style={{ listStyle: "none" }}>
                                  {<li><h6 className="sectionTitleCoursePage2"><strong>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z" /> <path d="M3 7l8.445-5.63a1 1 0 0 1 1.11 0L21 7v14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7zm2 1.07V20h14V8.07l-7-4.666L5 8.07zM8 16h8v2H8v-2zm0-3h8v2H8v-2zm4-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" /> </g> </svg>
                                    {session.price ? session.price : 0}$ </strong>
                                  </h6></li>}
                                  {sessionTotalDuration(session) > 0 && <li> <h6 className="sectionTitleCoursePage2"><strong>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-play-btn" viewBox="0 0 16 16"> <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" /> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" /> </svg>
                                    {sessionTotalDuration(session)} minutes du video formation</strong>
                                  </h6></li>}
                                  {sessionTotalExams(session) > 0 &&
                                    <li><h6 className="sectionTitleCoursePage2"><strong>
                                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-file-text" viewBox="0 0 16 16"> <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" /> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" /> </svg> */}
                                      <img src='/assets/icons/test-icon.svg' />

                                      {sessionTotalExams(session)} {sessionTotalExams(session) > 1 ? " exams" : " exam"} </strong>
                                    </h6></li>
                                  }</ul>
                              </div>
                              <div className="col-md-6 courseDetailsAccorUl" style={{ textAlign: "left", paddingLeft: "40px" }}>
                                {/* <h6 className="sectionTitleCoursePage2">
              <strong>This session contains {session.modules.length} {session.modules.length>1?" modules":" module"} 
              </strong> </h6> */}
                                <ul>
                                  {
                                    session.modules.map((module, indexModule) => {
                                      return (
                                        <li key={index}>{module.title}</li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            </div>
                          }
                          <br />
                        </div><br />
                        <div className="row col-md-12 text-center courseDetails" style={{ margin: "auto" }}>
                          {session.imageSessions && session.imageSessions.length > 0 &&
                            <div className="col-md-6 courseDetailsMediaAccor" style={{ paddingLeft: "0px", paddingRight: "20px" }}>
                              <CCarousel controls indicators style={{ padding: '0px' }} className="courseSlides">
                                {session.imageSessions.map((image, index) => {
                                  return (
                                    image.path &&
                                    <CCarouselItem key={index}>
                                      <CImage className="d-block w-100"
                                        key={index}
                                        src={MEDIA_URL + image.path}
                                      />
                                    </CCarouselItem>
                                  )
                                })}
                              </CCarousel>
                            </div>}
                          {session.demo && session.demo.length > 0 &&
                            <div className="col-md-6 courseDetailsMediaAccor" style={{ paddingRight: "0px", paddingLeft: "20px" }}>
                              <video autoPlay="autoPlay" width="100%" controls muted style={{ borderRadius: '5px' }} key={session.demo + session.id}>
                                <source src={MEDIA_URL + session.demo} type="video/mp4" key={session.demo + session.id} />
                              </video>
                            </div>}
                        </div>

                      </CAccordionBody>
                    </CAccordionCollapse>
                  </CAccordionItem>
                )
              })}
            </CAccordion>
          </div>
          {/* {sessionSelected && 
       <div className="col-md-6" style={{textAlign:"left",paddingLeft:"40px"}}>
            <div className="courseDetailsCol6" style={{paddingLeft:"15px"}}>  <h3 style={{textTransform:"capitalize"}}><p>{sessionSelected.title}</p></h3>
              {sessionSelected.description && sessionSelected.description!='null' && <h6><strong className="sectionTitleCoursePage">Description:</strong>
                <p dangerouslySetInnerHTML={{ __html: sessionSelected.description }}></p>
              </h6>}
              
              {sessionSelected.modules && sessionSelected.modules.length>0 &&
               <><ul style={{listStyle:"none"}}>
               <li><h6 className="sectionTitleCoursePage2"><strong>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M3 7l8.445-5.63a1 1 0 0 1 1.11 0L21 7v14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7zm2 1.07V20h14V8.07l-7-4.666L5 8.07zM8 16h8v2H8v-2zm0-3h8v2H8v-2zm4-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/> </g> </svg>
               {sessionSelected.price}$ </strong>
                </h6></li>
                <li> <h6 className="sectionTitleCoursePage2"><strong>
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-play-btn" viewBox="0 0 16 16"> <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/> </svg>
               {sessionTotalDuration(sessionSelected)} minutes du video formation</strong>
                </h6></li>
              {sessionTotalExams(sessionSelected)>0 && 
              <li><h6 className="sectionTitleCoursePage2"><strong>
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16"> <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/> </svg>  
              {sessionTotalExams(sessionSelected)} {sessionTotalExams(sessionSelected)>1?" exams":" exam"} </strong> 
              </h6></li>
              }</ul>
              <h6 className="sectionTitleCoursePage2"><strong>This session contains {sessionSelected.modules.length} {sessionSelected.modules.length>1?" modules":" module"} </strong> </h6>
                              <ul>
                                {
                                  sessionSelected.modules.map((module, indexModule) => {
                                    return (
                                      <li>{module.title}</li>
                                    )
                                  })
                                }
                              </ul>
       
                              </>}<br/></div><br/>

      {sessionSelected.demo && <video autoPlay="autoPlay" width="100%" controls muted style={{ borderRadius: '5px'}} key={sessionSelected.demo + sessionSelected.id}>
            <source src={MEDIA_URL+sessionSelected.demo} type="video/mp4" key={sessionSelected.demo + sessionSelected.id}/>
          </video>}
             {sessionSelected.demoimages && sessionSelected.demoimages.length>0 &&
              <div className="row">
              <CCarousel controls indicators >
                  {((sessionSelected.demoimages).split(",")).map((image,index) => {
                    return(
                      image.length>3 &&
                      <CCarouselItem key={index}>
                        <CImage className="d-block w-100"
                          key={index}
                          src={MEDIA_URL+image}
                        />
                      </CCarouselItem>                
                    )
                  })}
              </CCarousel>
              </div>
              }                     
           

        <br/></div> }  */}

        </div>

        <br />
        <br />
        {/* <div className="row col-md-12 text-center courseDetails" style={{ margin: 'auto', }}>
          <Link to={`/course/${formation.id}/${formation.slug}`} className="startCourseLink">
            Start the course
          </Link>
        </div>
        <br />
        <br /> */}

        {/* <CModal size="xl" alignment="center" visible={readModule} onClose={() => setReadModule(false)} draggable={true} >
            <CModalBody>
                <>  <p className="primary" style={{marginLeft :'20px'}}> Formation / Session / Module</p><hr/>
                    <div className="form-group" style={{margin :'20px'}}>
                        <div className="row">
                            <div className="col-md-4">
                                <p htmlFor="price"> Titre de module</p>
                                <p><b>{moduleTitle}</b></p>
                            </div>
                            <div className="col-md-4">
                                <p htmlFor="price"> Prix de module</p>
                                <p><b>{modulePrice} </b>DTN</p>
                            </div>
                            <div className="col-md-4">
                                <p htmlFor="price"> Durée de module</p>
                                <p><b>{moduleDuration}</b> Jour(s)</p>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <p dangerouslySetInnerHTML={{ __html: moduleDescription }}></p>
                        </div>
                    </div>
                    <br/>
                </>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={() => setReadModule(false)}> Annuler </CButton>
                <CButton color="primary" onClick={() => console.log()} >Ajouter au panier</CButton>
            </CModalFooter>
        </CModal> */}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  console.log(state)
  return {
    basketItems: state.basket.basketItems
  };
}
export default connect(mapStateToProps, actions)(Course)